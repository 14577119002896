<script>
import rules from '../utils/validators'

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    rules
  }),
  computed: {
    validators () {
      const validators = []
      const { rules } = this.field
      if (rules) {
        rules.forEach((rule) => {
          const constraint = typeof rule.arg !== 'undefined' ? this.rules[rule.name](rule.arg) : this.rules[rule] // we use typeof because arg might be '0', e.g. in greaterThan validator
          validators.push(constraint)
        })
      }
      return validators
    }
  }
}
</script>
